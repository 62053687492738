<template>
  <v-container class="pt-4 px-4">
    <v-row>
      <v-col cols="12" md="12">
        <v-card class="d-flex align-center rounded-lg pa-2" flat>
          <v-row class="pa-0 ma-0">
            <v-autocomplete
              class="ma-2"
              v-model="subjectSelect"
              :items="lectures"
              outlined
              :label="$t('degrees.selectSubject')"
              item-text="subjectName"
              item-value="subjectGuid"
              hide-details
              dense
              @change="clearSelect"
            ></v-autocomplete>
            <v-autocomplete
              class="ma-2"
              v-model="deparmentSelect"
              :items="departmentList"
              outlined
              :label="$t('home.departmentChoese')"
              item-text="departmentName"
              item-value="departmentGuid"
              hide-details
              dense
              @change="clearSelect2"
            >
            </v-autocomplete>
            <v-autocomplete
              class="ma-2"
              v-model="stageSelect"
              :items="stageList"
              outlined
              :label="$t('lecOne.selectStage')"
              :item-text="getFieldText"
              item-value="stageGuid"
              hide-details
              dense
              @change="clearSelect3"
            >
            </v-autocomplete>
            <v-autocomplete
              class="ma-2"
              v-model="sectionSelect"
              :items="sectionList"
              outlined
              :label="$t('mail.selectSection')"
              item-text="sectionName"
              item-value="sectionGuid"
              hide-details
              dense
              @change="getStudent"
            ></v-autocomplete>
            <v-autocomplete
              class="ma-2"
              v-model="groupSelect"
              :items="groupList"
              outlined
              :label="$t('mail.selectGroup')"
              item-text="groupName"
              item-value="groupGuid"
              hide-details
              dense
              @change="getStudent"
            ></v-autocomplete>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="ma-2"
                  outlined
                  dense
                  hide-details
                  v-model="date"
                  :label="$t('date')"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>

              <v-date-picker
                v-model="date"
                @input="menu = false"
                @change="getStudent"
              ></v-date-picker>
            </v-menu>
            <!-- <v-col cols="12" md="2" sm="12" class="py-0 ma-0 pt-1">
              <v-btn
                block
                tile
                color="primary"
                @click="getStudent"
                class="rounded-xl"
              >
                {{ $t("search") }}
                <v-icon right> mdi-magnify </v-icon>
              </v-btn>
            </v-col> -->
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-btn
                class="ma-2"
                :disabled="!getFullSectionName"
                color="primary"
                @click="qrCodeNewList"
                :title="$t('abs.NewListTitle')"
              >
                {{ $t("abs.NewList") }}
                <v-icon right> mdi-newspaper-plus </v-icon>
              </v-btn>

              <v-btn
                :disabled="!enableQrCode"
                color="secondary"
                class="ma-2"
                @click="openQrCode"
                :title="$t('abs.QRCodeTitle')"
              >
                {{ $t("abs.QRCode") }}
                <v-icon right> mdi-qrcode </v-icon>
              </v-btn>
              <v-btn
                color="primary"
                class="ma-2"
                @click="exportToXlsx"
                :loading="loading"
                :disabled="!getFullSectionName"
              >
                {{ $t("Export") }}
                <v-icon class="px-2">mdi-microsoft-excel</v-icon>
              </v-btn>
              <v-btn
                class="ma-2"
                color="primary"
                @click="getStudent"
                :loading="loading"
                :disabled="!getFullSectionName"
              >
                {{ $t("refresh") }}
                <v-icon class="px-2">mdi-refresh</v-icon>
              </v-btn>
              <v-spacer></v-spacer>

              <v-btn
                class="ma-2"
                outlined
                :disabled="!enableQrCode"
                color="secondary"
                @click="qrCodeCommit"
                :title="$t('abs.CommitTitle')"
              >
                {{ $t("abs.Commit") }}
                <v-icon right> mdi-text-box-check-outline </v-icon>
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row >
      <v-col cols="12" md="12">
        <v-card class="d-flex align-center rounded-lg pa-2 pb-0" flat >
          <DxDataGrid
            class="dx-swatch-dark "
            :column-hiding-enabled="true"
            id="gridContainer"
            :rtl-enabled="$i18n.locale == 'ar' ? true : false"
            :data-source="absenceList"
            :show-column-lines="showColumnLines"
            :show-row-lines="showRowLines"
            :show-borders="showBorders"
            :row-alternation-enabled="rowAlternationEnabled"
            :remote-operations="true"
            :repaint-changes-only="true"
            @saving="onSaving"
            :allow-column-resizing="false"
            :column-min-width="50"
            :on-cell-prepared="onCellPrepared"
            wrapEnabled='true'
          >
            <DxHeaderFilter :visible="true" />
            <DxLoadPanel :enabled="true" />
            <DxFilterRow :visible="true" />

            <DxPaging :page-size="10" />
            <DxPager
              :show-page-size-selector="true"
              :allowed-page-sizes="pageSizes"
              :show-info="false"
              :show-navigation-buttons="true"
              :visible="true"
            />
            <DxEditing
              mode="batch"
              :allow-adding="false"
              :allow-deleting="false"
              :allow-updating="true"
              class="txtRight"
            />
            <DxSearchPanel :visible="true" :placeholder="$t('search')" />

            <!-- <DxColumn data-field="id" :allow-editing="false" /> -->
            <DxColumn
              :hiding-priority="5"
              :caption="$t('abs.RegisterAs')"
              cell-template="template-changeAbsence"
              width="180"
              alignment="center"
            />

            <template #template-changeAbsence="{ data }">
              <v-btn outlined
                class="ma-1"
                :color=" data.data.hoursCount == null ? 'secondary':'primary'"
                :key="data.data.studentGuid"
                @click="changeAbsence(data.data.studentGuid)"
                :title="data.data.hoursCount == null
                    ? $t('abs.RegisterAbsent')
                    : $t('abs.RegisterPresent')"
              >
                {{
                  data.data.hoursCount == null
                    ? $t("abs.Absent")
                    : $t("abs.Present")
                }}
                <v-icon  right>{{data.data.hoursCount == null ? 'mdi-account-remove':'mdi-account-check'}} </v-icon>
              </v-btn>
            </template>

            <DxColumn
              class="dx-swatch-dark "
              :hiding-priority="3"
              data-field="studentName"
              :allow-editing="false"
              id="txtRight"
              :caption="$t('name')"
            />
            <DxColumn
              :hiding-priority="2"
              :caption="$t('abs.Gender')"
              cell-template="template-action"
              width="150"
              alignment="center"
            />
            <template #template-action="{ data }">
              <div>
                {{
                  data.data.studentGender == 1
                    ? $t("abs.Male")
                    : $t("abs.Female")
                }}
              </div>
            </template>

            <DxColumn
              :hiding-priority="4"
              :caption="$t('mail.status')"
              cell-template="template-status"
              width="150"
              alignment="center"
            />
            <template #template-status="{ data }">
              <div>
                <v-chip
                  class="py-0 my-0"
                  :class="
                    data.data.hoursCount != null ? 'error' : 'teal white--text'
                  "
                >
                  {{
                    data.data.hoursCount != null
                      ? $t("abs.Absent")
                      : $t("abs.Present")
                  }}
                </v-chip>
              </div>
            </template>
            <DxColumn
              :hiding-priority="1"
              data-field="hoursCount"
              :caption="$t('abs.HoursOfAbsence')"
              alignment="center"
              width="150"
            >
              <DxRequiredRule :message="$t('abs.ThisFieldIsRequired')" />
              <DxNumericRule :message="$t('abs.ItShouldBeANumber')" />
              <DxRangeRule
                :message="$t('abs.TheNumberOfHoursMustBeBetween0And7')"
                max="7"
                min="0"
              />
            </DxColumn>
            <DxColumn
              data-field="notes"
              :caption="$t('abs.Notes')"
              :hiding-priority="0"
            />

            <DxSummary :key="overlay">
              <DxTotalItem
                :customize-text="getAbsenceStudentsSummaryData"
                column="studentName"
              />
              <DxTotalItem
                :customize-text="getCurrentStudentsSummaryData"
                column="hoursCount"
              />
            </DxSummary>
          </DxDataGrid>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogQrCode" fullscreen>
      <v-card v-if="getFullSectionName">
        <v-toolbar color="primary darken-1" dark
          >{{ $t("abs.AttendanceRegistration") }}
          {{ getFullSectionName }}
          <v-spacer></v-spacer>
          <v-btn
            large
            icon
            dark
            @click="
              dialogQrCode = false;
              getStudent();
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row v-resize="resizeQrCode">
            <v-col cols="12" md="10" style="background-color: white">
              <qrcode-vue
                style="text-align: center; margin: 10px"
                :size="qrCodeSize"
                level="M"
                :value="QrCodeValue"
              ></qrcode-vue>
            </v-col>
            <v-col cols="12" md="2">
              <v-card class="pa-3 mt-3">
                <div
                  class="mt-3 mb-3"
                  style="font-size: large; font-weight: bold"
                >
                  {{ $t("abs.PresentNum") }} :{{ getCurrentStudents }}
                </div>
                <div style="font-size: large; color: red; font-weight: bold">
                  {{ $t("abs.AbsentNum") }} :{{ getAbsenceStudents }}
                </div>
                <v-divider class="mt-3"></v-divider>
                <v-data-table
                  :headers="headersAbs"
                  :items="getStudentAbs"
                  :items-per-page="5"
                >
                  <template v-slot:[`item.studentName`]="{ item }">
                    <v-card-title style="color: red; word-break: inherit">{{
                      item.studentName
                    }}</v-card-title>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxSearchPanel,
  DxPager,
  DxPaging,
  DxColumnChooser,
  DxLoadPanel,
  DxRequiredRule,
  DxNumericRule,
  DxRangeRule,
  DxHeaderFilter,
  DxFilterRow,
  DxTotalItem,
  DxSummary,
} from "devextreme-vue/data-grid";

import QrcodeVue from "qrcode.vue";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxSearchPanel,
    DxPager,
    DxPaging,
    DxColumnChooser,
    DxLoadPanel,
    DxRequiredRule,
    DxNumericRule,
    DxRangeRule,
    DxHeaderFilter,
    DxFilterRow,
    QrcodeVue,
    DxTotalItem,
    DxSummary,
  },
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      dialogQrCode: false,
      QrCodeValue: "",
      placeholder: this.$t("search"),
      rtlEnabled: true,
      showColumnLines: true,
      showRowLines: true,
      showBorders: true,
      rowAlternationEnabled: true,
      overlay: false,
      loading: false,
      absenceList: [],
      dataUpdata: [],
      pageSizes: [10, 15, 20],
      subjectSelect: null,
      lectures: [],
      deparmentSelect: null,
      stageSelect: null,
      sectionSelect: null,
      groupSelect: null,
      isdark: false,
      qrCodeSize: 600,
      qrTimer: null,
      refreshQrCodeEverySeconds: 12,
      allStudents: [],
      headersAbs: [
        {
          text: this.$t("name"),
          value: "studentName",
        },
      ],
    };
  },
  watch: {
    dialogQrCode() {
      if (this.qrTimer !== null && this.dialogQrCode == false) {
        clearInterval(this.qrTimer);
      }
    },
  },
  computed: {
    departmentList() {
      let dataList = [];
      if (this.subjectSelect) {
        dataList = this.lectures.filter(
          (item) => item.subjectGuid === this.subjectSelect
        );
        // console.log(dataList);
        return dataList[0].departments;
      }
      return dataList;
    },
    stageList() {
      let dataList = [];
      if (this.deparmentSelect) {
        dataList = this.departmentList.filter(
          (item) => item.departmentGuid === this.deparmentSelect
        );
        return dataList[0].stages;
      }
      return dataList;
    },
    sectionList() {
      let dataList = [];
      if (this.stageSelect) {
        dataList = this.stageList.filter(
          (item) => item.stageGuid === this.stageSelect
        );
        return dataList[0].sections;
      }
      return dataList;
    },
    groupList() {
      let dataList = [];
      if (this.sectionSelect) {
        dataList = this.sectionList.filter(
          (item) => item.sectionGuid === this.sectionSelect
        );
        return dataList[0].groups;
      }
      return dataList;
    },
    getSelectedGroup() {
      if (this.groupSelect) {
        return this.groupSelect;
      } else {
        return "";
      }
    },
    getFullSectionName() {
      if (
        this.subjectSelect &&
        this.deparmentSelect &&
        this.stageSelect &&
        this.sectionSelect &&
        this.absenceList.length > 0
      ) {
        const names = this.getSelectedName();

        // var subjectName = this.lectures.filter(
        //   (item) => item.subjectGuid === this.subjectSelect
        // );
        // var departmentName = this.departmentList.filter(
        //   (item) => item.departmentGuid === this.deparmentSelect
        // );
        // var stageName = this.stageList.filter(
        //   (item) => item.stageGuid === this.stageSelect
        // );

        // var sectionName = this.sectionList.filter(
        //   (item) => item.sectionGuid === this.sectionSelect
        // );

        return (
          ` ( ${
          this.date
          } ) | ${
          names.subjectName
          } / ${
          names.departmentName
          } / ${
          names.stageName
          } / ${
          names.sectionName}`
        );
      }

      return null;
    },
    enableQrCode() {
      return (
        this.getFullSectionName &&
        this.absenceList.filter((m) => m.hoursCount == 0).length > 0
      );
    },
    getStudentAbs() {
      return this.allStudents.filter((m) => m.hoursCount == 0);
    },
    getCurrentStudents() {
      return this.allStudents.filter((m) => m.hoursCount == null).length;
    },
    getAbsenceStudents() {
      return this.allStudents.filter((m) => m.hoursCount != null).length;
    },
  },
  methods: {
    getAbsenceStudentsSummaryData() {
      return `${this.$t("abs.AbsentNum")}: ${this.getAbsenceStudents}`;
    },
    getCurrentStudentsSummaryData() {
      return `${this.$t("abs.PresentNum")}: ${this.getCurrentStudents}`;
    },
    onCellPrepared: function (e) {
      if (e.rowType == "totalFooter") {
        const si = e.summaryItems.find((i) => i.column == "studentName");
        if (si) {
          e.cellElement.innerHTML = e.cellElement.innerHTML.replace(
            "dx-datagrid-summary-item",
            "dx-datagrid-summary-item red--text "
          );
        }
      }
    },
    getSelectedName() {
      const subjectName = this.subjectSelect
        ? this.lectures.filter(
            (item) => item.subjectGuid === this.subjectSelect
          )[0].subjectName
        : "";
      const departmentName = this.deparmentSelect
        ? this.departmentList.filter(
            (item) => item.departmentGuid === this.deparmentSelect
          )[0].departmentName
        : "";
      const stageName = this.stageList
        ? this.stageList.filter(
            (item) => item.stageGuid === this.stageSelect
          )[0].stageName
        : "";

      const sectionName = this.sectionList
        ? this.sectionList.filter(
            (item) => item.sectionGuid === this.sectionSelect
          )[0].sectionName
        : "";

      const groups = this.groupList
        ? this.groupList.filter((item) => item.groupGuid === this.groupSelect)
        : null;
      const groupName = groups && groups.length > 0 ? groups[0].groupName : "";

      return {
        subjectName,
        departmentName,
        stageName,
        sectionName,
        groupName,
        date: this.date,
      };
    },
    clearSelect() {
      this.sectionSelect = null;
      this.stageSelect = null;
      this.deparmentSelect = null;
    },
    clearSelect2() {
      this.sectionSelect = null;
      this.stageSelect = null;
    },
    clearSelect3() {
      this.sectionSelect = null;
    },
    onSaving(e) {
      //   this.overlay = true;
      this.dataUpdata = e.changes;
      //   console.log( this.dataUpdata);
      const data = [];
      for (let i = 0; i < this.dataUpdata.length; i++) {
        data.push({
          studentGuid: this.dataUpdata[i].key.studentGuid,
          notes: this.dataUpdata[i].data.notes,
          hoursCount: this.dataUpdata[i].data.hoursCount,
        });
        if (data[i].hoursCount == null) {
          data[i].hoursCount =
            this.dataUpdata[i].key.hoursCount == null
              ? 0
              : this.dataUpdata[i].key.hoursCount;
        }
        if (!data[i].notes) {
          data[i].notes = this.dataUpdata[i].key.notes;
        }
      }
      // console.log(data);
      this.axios
        .post("StudentsAbsence/AbsenceSave", {
          subjectGuid: this.subjectSelect,
          sectionGuid: this.sectionSelect,
          groupGuid: this.getSelectedGroup,
          datex: this.date,
          absenceStudents: data,
        })
        .then((res) => {
          this.getStudent();
          this.$swal.fire({
            title: this.$t("operationAccomplishedSuccessfully"),
            text: "",
            icon: "success",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    getLec() {
      this.axios
        .get("Subjects/Get")
        .then((res) => {
          if (this.isInRole(2)) {
            this.lectures = res.data.data.subjects;
          } else {
            this.lectures = res.data.data;
          }
        })
        .catch((err) => {});
    },
    getFieldText(item) {
      return `${item.stageName} - ${item.studyName}`;
    },
    getStudent() {
      if (this.sectionSelect && this.subjectSelect) {
        this.overlay = true;

        const url = `StudentsAbsence/Get?SectionGuid=${this.sectionSelect}&SubjectGuid=${this.subjectSelect}&Datex=${this.date}&GroupGuid=${this.getSelectedGroup}`;

        this.axios
          .get(url)
          .then((res) => {
            this.absenceList = res.data.data;
            this.allStudents = res.data.data;
          })
          .catch(() => {})
          .finally(() => {
            this.overlay = false;
          });
      } else {
        this.absenceList = [];
        this.allStudents = [];
      }
    },
    getAllStudent() {
      if (this.sectionSelect && this.subjectSelect) {
        this.overlay = true;

        const url = `StudentsAbsence/Get?SectionGuid=${this.sectionSelect}&SubjectGuid=${this.subjectSelect}&Datex=${this.date}&GroupGuid=${this.getSelectedGroup}`;

        this.axios
          .get(url)
          .then((res) => {
            this.allStudents = res.data.data;
          })
          .catch(() => {})
          .finally(() => {
            this.overlay = false;
          });
      }
    },
    qrCodeNewList() {
      if (this.sectionSelect && this.subjectSelect) {
        this.$swal
          .fire({
            title:
              `${this.$t("abs.NewListTitle")
              }\n${
              this.$t("AreYouSureAboutTheOperation")}`,
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: this.$t("yes"),
            cancelButtonText: this.$t("no"),
          })
          .then((result) => {
            if (result.value) {
              this.axios
                .post(`StudentsAbsence/QrCodeNew`, {
                  subjectGuid: this.subjectSelect,
                  sectionGuid: this.sectionSelect,
                  groupGuid: this.getSelectedGroup,
                  date: this.date,
                })
                .then((res) => {
                  if (res.data.statusCode === 1) {
                    this.getStudent();
                  } else {
                    this.$swal.fire({
                      title: this.$t("AnErrorOccurredDuringTheProcess"),
                      text: "",
                      icon: "error",
                      confirmButtonText: this.$t("close"),
                    });
                  }
                })
                .catch(() => {
                  this.$swal.fire({
                    title: this.$t("AnErrorOccurredDuringTheProcess"),
                    text: "",
                    icon: "error",
                    confirmButtonText: this.$t("close"),
                  });
                })
                .finally(() => {});
            }
          });
      }
    },
    getQrCode() {
      if (
        this.sectionSelect &&
        this.subjectSelect &&
        this.dialogQrCode == true
      ) {
        this.axios
          .post(`StudentsAbsence/QrCodeRefresh`, {
            subjectGuid: this.subjectSelect,
            sectionGuid: this.sectionSelect,
            groupGuid: this.getSelectedGroup,
            date: this.date,
          })
          .then((res) => {
            this.QrCodeValue = res.data.data.token;
            this.refreshQrCodeEverySeconds =
              res.data.data.refreshQrCodeEverySeconds;
            this.getAllStudent();
          })
          .catch(() => {})
          .finally(() => {});
      }
    },
    openQrCode() {
      this.dialogQrCode = true;
      this.getQrCode();
      this.qrTimer = setInterval(() => {
        this.getQrCode();
      }, this.refreshQrCodeEverySeconds * 1000);
    },
    qrCodeCommit() {
      this.$swal
        .fire({
          title:
            `${this.$t("abs.CommitTitle")
            }\n${
            this.$t("AreYouSureAboutTheOperation")}`,
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
        })
        .then((result) => {
          if (result.value) {
            this.axios
              .post(`StudentsAbsence/QrCodeCommit`, {
                subjectGuid: this.subjectSelect,
                sectionGuid: this.sectionSelect,
                groupGuid: this.getSelectedGroup,
                date: this.date,
              })
              .then((res) => {
                if (res.data.statusCode === 1) {
                  this.getStudent();
                } else {
                  this.$swal.fire({
                    title: this.$t("AnErrorOccurredDuringTheProcess"),
                    text: "",
                    icon: "error",
                    confirmButtonText: this.$t("close"),
                  });
                }
              })
              .catch(() => {
                this.$swal.fire({
                  title: this.$t("AnErrorOccurredDuringTheProcess"),
                  text: "",
                  icon: "error",
                  confirmButtonText: this.$t("close"),
                });
              })
              .finally(() => {});
          }
        });
    },

    resizeQrCode() {
      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (width > height) {
        this.qrCodeSize = height - 150;
      } else {
        this.qrCodeSize = width - 150;
      }
    },

    exportToXlsx() {
      this.loading = true;
      this.axios({
        url: `StudentsAbsence/ExportToXlsx?SubjectGuid=${
          this.subjectSelect
        }&SectionGuid=${this.sectionSelect}&Datex=${
          this.date
        }&Lang=${localStorage.getItem("lang")}&GroupGuid=${
          this.getSelectedGroup
        }`,
        method: "GET",
        responseType: "arraybuffer",
      })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          const names = this.getSelectedName();
          const filename = `${names.departmentName} - ${names.stageName} - ${names.sectionName} - ${names.groupName} - ${names.subjectName} - ${names.date}`;
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((e) => {
          console.log(e);
          this.$swal.fire({
            title: this.$t("TheFileDoesNotExist"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeAbsence(studentGuid) {
            this.axios
              .post(`StudentsAbsence/AbsenceChange`, {
                 subjectGuid: this.subjectSelect,
                 sectionGuid: this.sectionSelect,
                 groupGuid: this.getSelectedGroup,
                 datex: this.date,
                 studentGuid: studentGuid
              })
              .then((res) => {
                if (res.data.statusCode === 1) {
                  this.getStudent();
                } else {
                  this.$swal.fire({
                    title: this.$t("AnErrorOccurredDuringTheProcess"),
                    text: "",
                    icon: "error",
                    confirmButtonText: this.$t("close"),
                  });
                }
              })
              .catch(() => {
                this.$swal.fire({
                  title: this.$t("AnErrorOccurredDuringTheProcess"),
                  text: "",
                  icon: "error",
                  confirmButtonText: this.$t("close"),
                });
              })
              .finally(() => {});
    }
  },
  mounted() {
    this.resizeQrCode();
  },

  created() {
    this.getLec();
  },
};
</script>

<style lang="scss">
#txtRight {
  text-align: right !important;
}
.dx-datagrid .dx-row > td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.dx-datagrid-text-content{
        white-space: break-spaces;
}

</style>
